const logger = {
  debug: (message: string, data?: any) => {
    console.debug(message, data || '');
  },

  info: (message: string, data?: any) => {
    console.log(message, data || '');
  },

  error: (message: string, data?: any) => {
    console.error(message, data || '');
  },
};

export default logger;
