export type NotificationType = 'success' | 'info' | 'warning' | 'error';
export enum GCTeams {
  TAX = 'TAX',
  FINANCE = 'FINANCE',
}

export enum Companies {
  AUMNI = 'AUMNI',
  ALLVUE = 'ALLVUE',
  AFFINITY = 'AFFINITY',
  NETSUITE = 'NETSUITE',
  NETSUITE_FUNDS = 'FUNDS',
  SALES_FORCE = 'SALES_FORCE',
}

export enum MergeCompanies {
  AUMNI_TAX = 'AUMNI_TAX',
  AUMNI_FINANCE = 'AUMNI_FINANCE',
}

export enum CompaniesRoutes {
  AUMNI = 'match-companies-aumni',
  ALLVUE = 'match-companies-allvue',
  AFFINITY = 'match-companies-affinity',
  NETSUITE = 'match-companies-netsuite',
  AUMNI_MERGE_TAX = 'merge-companies-tax',
  AUMNI_MERGE_FINANCE = 'merge-companies-finance',
  AUMNI_UNMERGE_TAX = 'unmerge-companies-tax',
  AUMNI_UNMERGE_FINANCE = 'unmerge-companies-finance',
  MERGE_NETSUITE_DUPLICATES = 'merge-netsuite-duplicates-companies',
}

export enum DealsRoutes {
  MATCH_TRANSACTIONS = 'match-transactions',
  UNMATCH_TRANSACTIONS = 'unmatch-transactions',
}

export enum FundsRoutes {
  FUNDS = 'match-netsuite-funds',
  MERGE_NETSUITE_DUPLICATES = 'merge-netsuite-duplicates-funds',
}

export enum ReportingRoutes {
  VALUATION_DASHBOARD = 'valuation-dashboard',
  NEW_INVESTMENT_ACTIVITIES = 'new-investment-activities',
  FUND_OVERVIEW_DOWNLOAD = 'fund-overview-download',
  FUND_OVERVIEW_BLOCKERS_MANAGEMENT = 'fund-overview-blockers-management',
  FUND_OVERVIEW_DISPLAY_NAMES = 'fund-overview-display-names',
  COINVEST_TEAR = 'coinvest-tear',
}

export enum ReclassType {
  percentage = 'percentage',
  amount = 'amount',
}

export enum CartaRoutes {
  CARTA = 'carta',
}

export type CellContentType =
  | 'boolean'
  | 'decimal'
  | 'currency'
  | 'percentage'
  | 'multiplicity'
  | 'date';
