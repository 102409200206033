import { gql } from '@apollo/client';

const typeDefs = gql`
  input OutputDealsInput {
    company_name: String
    fund__c: String
    closedate: String
    series__c: String
    shares__c: String
    purchase_cost__c: String
    primary_secondary__c: String
    total_round__c: String
    pre_money__c: String
    lead_co_investor__c: String
    aumni_id: String
    sf_id: String
  }

  input MergeCompaniesInput {
    au_id: String
    sf_id: String
    website: String
    board_seat_holder: String
    company_incorporation_location: String
    total_liquidation_preference: String
  }

  input DeleteMergedCompaniesInput {
    au_id: String!
    sf_id: String!
    merge_time: String!
  }

  type Mutation {
    login(token: String!): AuthResponse
    saveOutputDeals(input: [OutputDealsInput!]): OperationResult
    deleteDeals(input: [String!]): OperationResult
    mergeCompanies(input: MergeCompaniesInput): OperationResult
    deleteMergedCompanies(input: [DeleteMergedCompaniesInput!]): OperationResult
  }

  type Query {
    aumniDealsByCompanyName(company_name: String!): [AumniDeal!]
    unmatchedDealsCompanies: [String!]
    sfDealsByCompany(company_name: String!): [SalesForceDeal!]
    matchedDeals: [MatchedDeal!]
    sfOpportunities(id: String!): [FieldValue!]
    transactionLedger(id: String!): [FieldValue!]
    portfolioCompanies: [PortfolioCompany!]
    sfAccounts: [SfAccount!]
    unmatchedCompanies: [UnmatchedCompanies!]
    unmatchedCompaniesPair(
      sf_id: String!
      au_id: String!
    ): UnmatchedCompaniesPair!
    matchedCompanies: [MatchedCompany!]
    legalFees: [LegalFee!]
    legalFeesYears: [Year!]
    legalFeesFunds: [TaxTransactionFund!]
    legalFeesCompanies: [TaxTransactionCompany!]
    legalFeesIssueTypes: [TaxTransactionIssueType!]
  }

  scalar Date

  type AuthResponse {
    success: Boolean!
    token: String
  }

  type AumniDeal {
    series__c: String
    shares__c: String
    purchase_cost__c: String
    primary_secondary__c: String
    total_round__c: String
    pre_money__c: String
    lead_co_investor__c: String
    closedate: String
    company_name: String
    fund__c: String
    aumni_id: String
  }

  type SalesForceDeal {
    series__c: String
    shares__c: String
    purchase_cost__c: String
    primary_secondary__c: String
    total_round__c: String
    pre_money__c: String
    lead_co_investor__c: String
    closedate: String
    company_name: String
    fund__c: String
    sf_id: String
  }

  type MatchedDeal {
    aumni_id: String
    sf_id: String
    aumni_composite_id: String
    sf_composite_id: String
    company_name: String
    closedate: String
    series__c: String
    shares__c: String
    purchase_cost__c: String
    primary_secondary__c: String
    total_round__c: String
    pre_money__c: String
    lead_co_investor__c: String
    merge_type: String
    user_email: String
    merge_time: String
  }

  type PortfolioCompany {
    portfolio_company_id: String
    portfolio_company_name: String
    total_aggregate_liquidation_preference: String
    corporate_jurisdiction: String
    board_appointees: String
    company_url: String
  }

  type SfAccount {
    Id: String
    Name: String
    Total_Liquidation_Preference__c: String
    Company_Incorporation_Location__c: String
    Board_Seat_Holder__c: String
    Website: String
  }

  type OperationResult {
    success: Boolean!
    message: String
  }

  type FieldValue {
    field: String!
    value: String!
  }

  type UnmatchedCompanies {
    sf_id: String!
    au_id: String!
    company_name: String!
  }

  type UnmatchedCompaniesPair {
    au_website: String!
    sf_website: String!
    au_board_seat_holder: String!
    sf_board_seat_holder: String!
    au_total_liquidation_preference: String!
    sf_total_liquidation_preference: String!
    au_company_incorporation_location: String!
    sf_company_incorporation_location: String!
  }

  type MatchedCompany {
    au_id: String!
    sf_id: String!
    user_email: String
    merge_time: String!
    website: String
    board_seat_holder: String
    company_incorporation_location: String
    total_liquidation_preference: String
    company_name: String
  }

  type GLTaxTransaction {
    transaction_id: ID!
    amount: Float!
    account_number: Int!
    document_number: String!
    description: String!
    company_name: String
    issuer_code: String
    data_issues: [TaxTransactionIssueType!]
    fund_name: String!
    fund_code: String!
    posting_year: Int!
    posting_date: Date!
    m1_adjustment: Float
    taxable_income: Float
    company_finance_lead: String
    notes: String
    schedule_k1_line: String!
    activity: TaxTransactionActivity
    reclassed_schedule_k1_mapping: String
    reclassed_taxable_income: Float
    reclass_type: ReclassType
    fdap_category: String
  }

  type TaxTransactionFund {
    name: String!
    code: String!
  }

  type TaxTransactionCompany {
    name: String
    code: String
  }

  enum TaxTransactionIssueType {
    POSITIVE_EXPENSE
    EMPTY_ACTIVITY
    MISSING_ISSUER
    MULTIPLE_ISSUER_CODES
    MULTIPLE_COMPANY_NAMES
    MATERIALITY_TWENTY_GRANDS
    NO_ISSUES
  }

  enum TaxTransactionActivity {
    EXPENSED
    CAPITALIZED
    REALIZED
  }

  enum ReclassType {
    percentage
    amount
  }
`;

// TODO: Check and remove ReclassType

export default typeDefs;
