import type { PropsWithChildren } from 'react';
import cx from 'clsx';
import { useRouter } from 'next/router';
import { hashRoute } from '@/lib/utils';
import { ReportingRoutes } from '@/types/enums';
import styles from './Layout.module.scss';
import { Navigation } from './nav/Navigation';

export function NavLayout({ children }: PropsWithChildren) {
  const router = useRouter();
  const path = hashRoute(router.asPath, '');

  const isReportPage =
    path === ReportingRoutes.VALUATION_DASHBOARD ||
    path === ReportingRoutes.NEW_INVESTMENT_ACTIVITIES ||
    path === ReportingRoutes.COINVEST_TEAR;

  return (
    <>
      <div className={cx(styles.page, isReportPage && styles.reportPage)}>
        <div className={styles.nav}>
          <Navigation />
        </div>
        <main className={styles.main}>{children}</main>
      </div>
    </>
  );
}
