import { ChartTypes } from '@/types/portfolio';

export const COMPANY_CLARIFICATIONS = [
  'Select a lead investor or company to update exit information and calculate a future realization amount.',
  'To update the global exit, please enter data in Affinity, which is linked below.',
  'The projected realized value will adjust based on the inputed EV; for EV’s under the outstanding liquidation preference, we assume to recover our preferred ownership % of the exit value at 1x.',
  'For simplicity, Innovation / RDV / Y Combinator funds have been rolled up into their parent funds.',
  'Use the Edit Table button below to add/remove columns from view on the table.',
];

export const FUND_CLARIFICATIONS = [
  'Select a fund or multiple funds to view the impact of company exit data at the fund level.',
  'Exit IRR based on cashflows.',
  'Companies without exit data are included in the last period on the chart at their current EV.',
];

export const CHART_MAPPER = {
  [ChartTypes.MY_COMPANIES]: 'My companies',
  [ChartTypes.OTHER_COMPANIES]: 'Other Companies',
  [ChartTypes.MODELLED_COMPANIES]: 'Modelled Companies',
};
