import { decodeJwt } from '@/lib/utils';

export const isAuthenticated = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('authToken');

    if (token) {
      const { exp }: any = decodeJwt(token);

      return exp > new Date().getTime() / 1000;
    }
  }
  return false;
};

export function getAuthToken() {
  const token = localStorage.getItem('authToken');

  return token ? `Bearer ${token}` : '';
}
