import jwt_decode from 'jwt-decode';

export const sortOptions = (options: any) => {
  if (!options) return options;

  return [...options].sort((a: any, b: any) => (a.label > b.label ? 1 : -1));
};

export const sortOptionsDesc = (options: any) => {
  if (!options) return options;

  return [...options].sort((a: any, b: any) => (a.label < b.label ? 1 : -1));
};

export const onlyUnique = (value: string, index: number, self: any) =>
  self.indexOf(value) === index;

export const domainNameOrOriginalValue = (value: string) => {
  try {
    return new URL(value).hostname.replace('www.', '');
  } catch {
    if (value) {
      return value.replace('www.', '');
    }

    return value;
  }
};

export const hashRoute = (route: string, defaultValue: string) =>
  route?.split('#')?.[1] || defaultValue;

export const decodeJwt = (token: string) => jwt_decode(token);

export const roundToTwoDecimals = (value: number) =>
  Math.round(value * 100) / 100;

export const compareNumberStrings = (first?: string, second?: string) => {
  if (!first || !second) {
    return false;
  }

  try {
    const firstParsed = parseFloat(first.replace(',', ''));
    const secondParsed = parseFloat(second.replace(',', ''));

    if (isNaN(firstParsed) && isNaN(secondParsed)) {
      return true;
    }

    return firstParsed === secondParsed;
  } catch (e) {
    return false;
  }
};

export const isTestMode = () =>
  process.env.NEXT_PUBLIC_TEST_MODE === 'TEST_MODE';
