export enum ChartTypes {
  MY_COMPANIES = 'MY_COMPANIES',
  OTHER_COMPANIES = 'OTHER_COMPANIES',
  MODELLED_COMPANIES = 'MODELLED_COMPANIES',
}

interface IChartItemDetails {
  companyId: string;
  companyName: string;
  value: number;
}

export type ChartItem = {
  value: number | string | IChartItemDetails[];
  name: string;
};

export type PointsItem = {
  period: string;
  type: ChartTypes;
  value: number;
  details: IChartItemDetails[];
};

export interface IChartDataResponse {
  portfolioManagementFundsChartData: {
    metadata: { projectedFrom: string };
    points: PointsItem[];
  };
}
