import { ChartTypes } from '@/types/portfolio';
import { CHART_MAPPER } from '../constants/portfolio';
import type { CellContentType } from '@/types/enums';
import { convertToUSADateFormat } from '@/utils/strings';

export const isAfter = (compareQuarter: string, quarter: string) => {
  const [compareQ, compareY] = compareQuarter.split(' ');
  const [q, y] = quarter.split(' ');

  const compareQuarterNumber = parseInt(compareQ.substring(1), 10);
  const compareYear = parseInt(compareY, 10);

  const quarterNumber = parseInt(q.substring(1), 10);
  const year = parseInt(y, 10);

  if (year > compareYear) {
    return true;
  } else if (year === compareYear) {
    return quarterNumber >= compareQuarterNumber;
  }
  return false;
};

export const isNotNullOrUndefined = <T>(data: T): data is NonNullable<T> =>
  data !== null && data !== undefined;

export const formatNumber = (
  number: number,
  decimalPlaces: number,
  postFix = 'x',
) => {
  if (!isNotNullOrUndefined(number)) {
    return '-';
  }

  const rounded = number.toFixed(decimalPlaces);
  return (rounded === '-0.00' ? '0.00' : rounded) + postFix;
};

const getPostFix = (type: CellContentType) => {
  switch (type) {
    case 'percentage':
      return '%';
    case 'multiplicity':
      return 'x';
    default:
      return '';
  }
};

export const formatValue = (value: number | string, type: CellContentType) => {
  if (!isNotNullOrUndefined(value)) {
    return '-';
  }

  if (type === 'date') {
    return convertToUSADateFormat(value as string);
  }

  if (typeof value === 'number') {
    const format = type === 'currency' ? 'currency' : 'decimal';
    const postFix = getPostFix(type);

    return (
      new Intl.NumberFormat('en-US', {
        style: format,
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value) + postFix
    );
  }

  return value;
};

export function getInitials(name: string | undefined) {
  return (
    name &&
    name
      .split(' ')
      .map((word) => word[0])
      .join('')
  );
}

export function arraysEqual(arr1: string[], arr2: string[]) {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((value, index) => value === arr2[index]);
}

export function getChartItemColor(type: string, isAfter = false) {
  switch (type) {
    case CHART_MAPPER[ChartTypes.MY_COMPANIES]:
      return isAfter ? '#FFCCA9' : '#F58F64';
    case CHART_MAPPER[ChartTypes.OTHER_COMPANIES]:
      return isAfter ? '#9898FF' : '#623BA5';
    case CHART_MAPPER[ChartTypes.MODELLED_COMPANIES]:
      return isAfter ? '#A9FFC2' : '#008000';
  }
}
